<template>
    <div>
        <img class="example-headImg" src="../assets/image/aboutus/07-0banner.png"/>
        <div class="head-explain">
            <div class="head-explain-title">加入我们</div>
            <div class="head-explain-content">携手打造智慧医疗</div>
        </div>
        <div class="container-1">
            <div class="joinus-title1">常招职位</div>
            <div class="joinus-title2">数智员工拥抱新技术，抓住机遇，推动行业进步。欢迎斗志昂扬、才华横溢、渴望创新的员工加入数智。</div>
            <div class="joinus-box">
                <div class="card">
                    <div class="title1">客户经理/客户总监</div>
                    <div class="title2">工作地点: 北京</div>
                </div>
                <div class="card">
                    <div class="title1">Java研发</div>
                    <div class="title2">工作地点: 西安</div>
                </div>
                <div class="card">
                    <div class="title1">运维实施</div>
                    <div class="title2">工作地点: 西安</div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'BizfocusPagesPurchaseCollaboration',

    data() {
        return {
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="less" scoped>
.joinus-title1{
    height: 3.6rem;
    font-size: 2.4rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #333333;
    line-height: 3.6rem;

    margin-top: 5.2rem;
}
.joinus-title2{
    height: 3.6rem;
    font-size: 2.4rem;
    font-family: 'CN_Regular';
    font-weight: 500;
    color: #333333;
    line-height: 3.6rem;

    margin-top: 5.2rem;
}
.joinus-box{
    width: 140rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    margin: 1rem 0 0 26rem;
    .card{
        width: 340px;
        height: 120px;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px 0px #E7E7E7;
        border-top: 3px solid #11A84F;

        margin-top: 4.1rem;
    }
    .title1{
        height: 3.6rem;
        font-size: 2.4rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #333333;
        line-height: 3.6rem;
        margin-top: 2.7rem;
    }
    .title2{
        height: 1.9rem;
        font-size: 1.6rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #999999;
        line-height: 2.2rem;
        margin-top: 0.4rem;
    }
}
</style>